import React from 'react'
import PropTypes from 'prop-types'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import { Tooltip, withStyles } from '@material-ui/core'

const InfoTooltip = ({ href, text, placement, color }) => {
  const StyledTooltip = withStyles({
    tooltip: {
      fontWeight: 'normal',
      background: 'black',
      fontSize: '1.2rem',
      display: text?.length ? 'inherit' : 'none'
    }
  })(Tooltip)

  return (
    <StyledTooltip
      placement={placement || 'top'}
      title={<span dangerouslySetInnerHTML={{ __html: text }}/>}
      className={'exclude-from-print'}
      style={{ color: color || 'initial' }}
      href={href}
    >
      <InfoIcon fontSize={'inherit'} />
    </StyledTooltip>
  )
}

InfoTooltip.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string,
  placement: PropTypes.string,
  color: PropTypes.string
}

export default InfoTooltip
