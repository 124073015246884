import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getPendingAssignments, getCompletedAssignments, getErrorMessage } from '../bundles/redux/selectors/generalSelector'
import { set_current_assignment } from '../bundles/redux/reducers/general'
import { setInSession } from '../utils/networkControllers/RailsRequests/AssignmentsController'
import { FormControl, FormControlLabel, Radio, RadioGroup, MenuItem, Button } from '@material-ui/core'
import Select from '@mui/material/Select'
import Grid from '@mui/material/Grid'

const AssignmentSelection = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [selectedValue, setSelectedValue] = useState('pending')
  const [currentAssignment, setCurrentAssignment] = useState(null)

  const pendingAssignments = useSelector(getPendingAssignments) || []
  const completedAssignments = useSelector(getCompletedAssignments) || []
  const error_message = useSelector(getErrorMessage)

  const assignments = selectedValue === 'pending' ? pendingAssignments : completedAssignments

  const handleChange = (event) => {
    setSelectedValue(event.target.value)

    setCurrentAssignment(null)
    dispatch(set_current_assignment(null))
  }

  const handleSelectChange = (event) => {
    const selectedId = event.target.value
    const assignment = assignments.find(assignment => assignment.id === selectedId)

    setInSession(assignment)
      .then(response => {
        setCurrentAssignment(assignment)
        dispatch(set_current_assignment(assignment))
      })
      .catch(error => {
        console.error('Error setting assignment:', error)
      })
  }

  const handleStartMapping = () => {
    navigate('/mapping')
  }

  return (
    <div className='assignment-selection'>
      {error_message && (
        <div className='error-message'>{error_message}</div>
      )}
      <span className='assignment-selection-title'>
        Select Assignment
      </span>
      <div>
        <Select
          value={currentAssignment ? currentAssignment.id : ''}
          onChange={handleSelectChange}
          style={{
            width: '300px',
            fontSize: '14px',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
            backgroundColor: !assignments.length ? '#d0d0d0' : 'transparent'
          }}
          disabled={!assignments.length}
          displayEmpty
        >
          {!assignments.length ? (
            <MenuItem disabled value=''>
              No assignments available
            </MenuItem>
          ) : (
            assignments?.map(assignment => (
              <MenuItem
                style={{ fontSize: '14px' }}
                key={assignment.id}
                value={assignment.id}
              >
                {assignment.name}
                &nbsp;
                <b>|</b>
                &nbsp;Created By: {assignment.created_by.username}
              </MenuItem>
            ))
          )}
        </Select>
      </div>
      <Grid item>
        <FormControl component='fieldset'>
          <RadioGroup
            aria-label='status'
            name='controlled-radio-buttons-group-status'
            value={selectedValue}
            onChange={handleChange}
          >
            <Grid container spacing={2} alignItems='center' justifyContent='center'>
              <Grid item>
                <span className='label-text'>Pending</span>
                <FormControlLabel
                  value='pending'
                  control={<Radio/>}
                />
              </Grid>
              <Grid item>
                <span className='label-text'>Completed</span>
                <FormControlLabel
                  value='completed'
                  control={<Radio/>}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item>
        <Button
          className='start-mapping'
          style={{
            padding: '10px',
            marginLeft: '10px',
            marginTop: '30px',
            backgroundColor: '#ffc700',
            borderRadius: '5px',
            fontFamily: "'Comfortaa', sans-serif",
            color: 'white',
            fontSize: '13px',
            opacity: !currentAssignment ? 0.6 : 1
          }}
          onClick={handleStartMapping}
          disabled={!currentAssignment}
        >
          <i className='fa-solid fa-play margin-right-10'></i>
          <span className='text' style={{ textTransform: 'none' }}>
            Start Mapping
          </span>
        </Button>
      </Grid>
    </div>
  )
}

export default AssignmentSelection
